.button {
  margin: 0;
  padding: 0;
  display: inline-block;
  background: none;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  color: var(--accent-main-primary);
  border: none;
}
