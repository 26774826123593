.container {
  margin: 0;
}

.anchor-next {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--gray6_100);
  text-decoration: none;
  color: var(--decorative-theme-dark);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

.anchor-icon {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: var(--accent-warning-primary);
}
