.content {
  position: relative;
  padding: 24px 30px 40px;
}

.title {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  color: black;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.15s;

  &:hover {
    color: #121212;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.description {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;

  strong {
    font-weight: bold;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: inline-block;
  }

  .form_top {
    display: flex;
    width: 100%;

    & > div:nth-child(1) {
      margin-right: 8px;
      width: 290px;
    }

    & > div:nth-child(2) {
      position: relative;
    }
  }

  .form_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;

    .form_bottom_checkbock {
      margin-top: 8px;
      width: 100%;
    }

    & > div:last-child {
      margin-top: 8px;
      width: 100%;
      font-family: Lato, sans-serif;
      font-size: 12px;
      text-align: left;
      color: #7a7a7a;
    }

    a {
      text-decoration: none;
      color: #2b87db;
    }
  }
}

.success {
  padding-left: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTQgMTAiPiAgPGRlZnM+ICAgIDxwYXRoIGlkPSJhIiBkPSJNMTQgMHY5LjVIMFYwaDE0eiIvPiAgPC9kZWZzPiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPiAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4gICAgPC9tYXNrPiAgICA8cGF0aCBmaWxsPSIjMkU5RTAwIiBkPSJNNS40NCA5LjVoLS4wMWExLjAzIDEuMDMgMCAwIDEtLjcyNy0uMzFMLjI5MyA0LjY4YTEuMDMgMS4wMyAwIDAgMSAxLjQ3My0xLjQ0bDMuNjg2IDMuNzdMMTIuMjQ3LjI5N2ExLjAzIDEuMDMgMCAxIDEgMS40NDcgMS40NjRMNi4xNjQgOS4yYy0uMTk0LjE5LS40NTQuMjk4LS43MjUuMjk4IiBtYXNrPSJ1cmwoI2IpIi8+ICA8L2c+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: 0 5px;
  background-size: 14px 10px;

  strong {
    font-weight: bold;
  }
}

.error {
  margin: 4px 0;
}
