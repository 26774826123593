.container {
  position: relative;
  margin-top: 8px;
  padding: 24px 120px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: content-box;
  min-height: 100px;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
}
.logo {
  width: 80px;
  height: 80px;
  background: no-repeat center;
  background-size: contain;
  border-radius: 4px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.title {
  all: unset;
  margin-top: 8px;
  cursor: pointer;
}

.title h1:hover {
  color: var(--accent-main-primary);
}

.status {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.status-title {
  margin-left: 6px;
}

.facts {
  display: flex;
  flex-wrap: wrap;
  margin: 8px -4px 24px 0;
}

.fact {
  margin-top: 16px;
  margin-right: 40px;
}

.fact:last-child {
  margin-right: 0;
}

.link {
  margin-top: 28px;
}
