.link {
  margin: 0 24px 0 0;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: var(--accent-main-primary);
}

.link:last-child {
  margin: 0;
}

.link:hover {
  color: var(--control-main-primary-hovered);
}
