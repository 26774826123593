.container {
  position: relative;
  height: 200px;
  background: var(--decorative-theme-dark);
  display: block;
  text-decoration: none;

  &::before {
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    height: 200px;
    width: 50%;
    background: var(--decorative-theme-dark);
    pointer-events: none;
    content: '';
  }
}

.content {
  position: relative;
  display: flex;
  z-index: 2;
  min-width: 952px;
  max-width: 1376px;
  padding: 0 20px;
  margin: 0 auto;
  color: var(--decorative-theme-white);
}

.left {
  display: flex;
  flex-direction: column;
}

.right {
  padding-top: 24px;
  margin-left: auto;
}

.factoids {
  display: flex;

  & > div {
    margin-right: 11px;
  }

  & > div:last-of-type {
    margin-left: 28px;
  }
}

.developer {
  display: flex;
  padding: 40px 0 22px;
  font-weight: bold;
}

.developer-preview {
  margin-right: 24px;
}

.developer-subtitle {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
}

.developer-title {
  font-size: 36px;
  line-height: 40px;
}
