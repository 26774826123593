.label {
  background-color: var(--accent-main-secondary);
  color: var(--accent-main-primary);
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
}

.container {
  max-width: 288px;
}

.description {
  margin-top: 8px;
}

.price_bar {
  margin-top: 16px;
}
