@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  position: relative;
  /* Компенсируем выступ абсолютно позиционированного trend-элемента, чтобы не закрывались соседи. */
  margin-right: 2px;
  padding: 0;
  display: inline-block;
  width: 38px;
  height: 30px;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.main {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 30px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: var(--gray6_100);
  border-radius: 4px;
}

.trend {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  color: var(--accent-positive-primary);
  background: var(--decorative-theme-white);
  border-radius: 50%;
}

.trend.inc {
  color: var(--accent-negative-primary);
}
