@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  font-size: 16px;
  color: var(--decorative-theme-dark);
  line-height: 20px;
  display: flex;
  flex-direction: column;
  max-width: 478px;
}

.items {
  margin-top: 16px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.icon {
  margin-top: 7px;
  margin-right: 16px;
  width: 28px;
  height: 28px;
}

.buttons-wrap {
  flex-direction: column;
  white-space: nowrap;
  text-align: left;
}
