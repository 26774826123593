.container {
  padding: 16px;
  background-color: var(--gray6_100);
  border-radius: 4px;
}

.phones {
  margin-bottom: var(--unit_4);
}

.info {
  display: flex;
  align-items: flex-start;
}

.info ~ .info {
  margin-top: var(--unit_2);
}

.icon {
  padding-right: 9px;
  padding-top: 3px;
  display: flex;
}
