.item {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.image {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  padding-left: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}
