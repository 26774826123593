.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

.title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.text {
  font-size: 14px;
  text-align: center;
  line-height: 1.43;
  margin-bottom: 24px;
}

.buttons-wrap {
  display: flex;
}

.button-wrap {
  margin-right: 10px;
  margin-left: 10px;
}
