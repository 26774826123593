@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.mark-container {
  display: flex;
  align-items: center;
  margin-bottom: auto;
  gap: 4px;
}

.review-rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.link {
  text-decoration: none;
}

.star {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.star-has-value {
  background-image: url('./assets/star.svg');
}

.star-not-has-value {
  background-image: url('./assets/star-without-mark.svg');
}
