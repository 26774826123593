.container {
  padding-top: var(--unit_4);
}

.icon {
  position: relative;
  top: 3px;
  display: inline;
  align-items: center;
  color: var(--accent-positive-primary);
}
