.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.container ~ .container {
  margin-top: 64px;
}
