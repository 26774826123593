.container {
  display: flex;
  padding: 0 24px 8px;
}

.container:empty {
  padding: 0;
}

.container > * + * {
  margin-left: 8px;
}
