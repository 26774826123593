.container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.info {
  flex: auto;
}

.link {
  flex: none;
  width: 220px;
}
