.root {
  margin-bottom: -4px;
}

.label {
  display: inline-block;
  padding: 0 6px;
  background: rgba(58, 197, 0, 0.1);
  color: var(--accent-positive-primary);
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  margin-bottom: 4px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}
