.section {
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: white;
}

.shadow {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.overflowed {
  overflow: hidden;
}

.borders {
  border: 1px solid #c9d1e5;
}

.spaces {
  padding: 24px;
}

.section:empty {
  display: none;
}

@media print {
  .hide-for-print {
    display: none;
  }
}
