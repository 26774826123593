.segment {
  position: absolute;
  z-index: 101;
  top: 35%;
  left: 35%;
  background-color: white;
  border-radius: 4px;
}

.body {
  position: relative;
  padding: 24px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.22);
  font-family: Lato; /* stylelint-disable-line */
  font-size: 16px;
  font-weight: normal;
  color: #121212;
}

.body .image {
  margin: 0 0 12px;
}

.body .bold {
  font-weight: bold;
}

.close_icon {
  position: absolute;
  top: 12px;
  right: 16px;
}
