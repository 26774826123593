.item {
  display: flex;
}

.img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.text {
  min-height: 0;
  padding-left: 20px;
}
