.container {
  display: flex;
  flex-direction: column;
  margin-top: var(--unit_4);

  & > :nth-child(2) {
    margin-top: var(--unit_6);
  }

  & > :nth-child(3) {
    margin-top: var(--unit_3);
  }
}

.image {
  width: 100%;
  border-radius: var(--unit_3);
}
