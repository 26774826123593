.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-top: var(--unit_6);

  &.mobile {
    border-top: 1px solid #c9d1e5;
    padding: var(--unit_3) var(--unit_4);
  }
}

.button {
  width: calc(50% - var(--unit_2) / 2);

  &:first-child {
    margin-right: var(--unit_2);
  }

  @media (min-width: 640px) {
    width: auto;
  }
}
