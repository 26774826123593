.container {
  margin-bottom: 8px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  height: 82px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.21;
  color: var(--decorative-theme-dark);
  background-color: var(--gray10_100);
  border-radius: 3px;
}
