.container {
  padding: 0 40px 40px;
}

.buttons-container {
  margin-top: 24px;
  display: flex;
}

.button:not(:first-child) {
  margin-left: 8px;
}
