.link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.arrow {
  margin-left: 8px;
  display: flex;
  visibility: hidden;
  align-items: center;
}

.link:hover .arrow {
  visibility: visible;
}
