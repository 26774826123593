.base {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
}

.small {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  text-align: left;
  letter-spacing: normal;
  color: #7a7a7a;
}

.h1 {
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  text-align: left;
  letter-spacing: normal;
}

.h2 {
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  text-align: left;
  letter-spacing: normal;
}

.h3 {
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  text-align: left;
  letter-spacing: normal;
}

.huge {
  font-family: Lato, sans-serif;
  font-size: 38px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 46px;
  text-align: left;
  letter-spacing: normal;
}

.bold {
  font-weight: bold;
}

.black {
  color: #121212;
}

.orange {
  color: #ff9d00;
}

.blue {
  color: #2b87db;
}

.gray {
  color: #8c8c8c;
}

.white {
  color: white;
}
