.container {
  width: 540px;
}

.error {
  margin-left: 16px;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  line-height: 20px;
  color: #e10000;
}

.options {
  margin: 20px 0 30px;
}
