.type_block {
  margin: 6px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* stylelint-disable-line */
  justify-content: center;
}

.type_block .type {
  margin: 0 0 0 6px;
  font-size: 14px;
  color: #7a7a7a;
}

.type_block--without_icon {
  .type {
    margin-left: 0;
  }
}
