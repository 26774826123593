.container {
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  transition: height 0.25s;
}

.fixed {
  position: fixed;
  overflow: hidden;
  height: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.active {
  overflow: visible;
  height: 52px;
}

.transition {
  overflow: hidden;
}

.fixed .wrapper {
  margin: 0 auto;
  padding: 0 20px;
  min-width: 952px;
  max-width: 1376px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aside,
.buttons {
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons .button + .button {
  margin-left: 8px;
}

@media print {
  .container {
    display: none;
  }
}
