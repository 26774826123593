.container {
  margin: 12px 24px 24px;
  display: flex;
  height: 165px;
  justify-content: center;
  align-items: center;
  background: #e4e4e4 url('./assets/placeholder.png') no-repeat;
  background-size: cover;
  border-radius: 4px;
}
