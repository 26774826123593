.container {
  margin: 0 0 8px;
  padding: 24px 16px;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 3px;

  &.disable-external-container-styles {
    margin: 0;
    border: none;
  }
}

.empty {
  opacity: 0;
  width: 0;
  height: 0;
}

.title_block {
  margin: 0 9px 23px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: var(--decorative-theme-dark);
}
