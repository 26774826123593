.item {
  margin-bottom: 8px;
}

.item--redesign {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.item:empty {
  display: none;
}
