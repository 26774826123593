.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
}
.title {
  font-size: 22px;
  text-align: center;
  line-height: 1.27;
}
.text {
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 24px;
}
