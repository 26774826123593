.section {
  background: white;
  border-radius: 2px;
  padding: 0 24px;
}

.item {
  padding-top: 64px;
}

.with-borders {
  border-radius: 8px;
  border: 1px solid #c9d1e5;
  overflow: hidden;
}

.inner {
  padding: 0;
}

.with-border {
  border-top: 1px solid #c9d1e5;
}

.with-bottom-border {
  border-bottom: 1px solid #c9d1e5;
}

.title {
  margin-bottom: var(--unit_10);
}

.heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  margin-top: var(--unit_6);
}

.overflow-hidden {
  overflow: hidden;
}

@media print {
  .hide-for-print {
    display: none;
  }
}
