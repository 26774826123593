.container {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--decorative-theme-white);
}

.top-block {
  margin-bottom: 24px;
  display: flex;
}

.title {
  display: flex;
  align-items: center;
}

.label-wrapper {
  margin-left: 8px;
}

.icon-wrapper {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.address-wrapper {
  margin-top: 8px;
}

.content {
  margin-bottom: 24px;
}

.content-title {
  margin-bottom: 12px;
}

.content-list {
  display: flex;
}

.content-column {
  width: 50%;
}

.content-column:first-child {
  margin-right: 24px;
}

.content-item {
  position: relative;
  margin-bottom: 8px;
  display: flex;
}

.content-item:last-child {
  margin-bottom: 0;
}

.bullet-wrapper {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 24px;
}

@media (max-width: 1199px) {
  .content-list {
    flex-wrap: wrap;
  }

  .content-column {
    width: 100%;
  }

  .content-column:first-child {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .bullet-wrapper {
    margin-right: 12px;
    margin-left: -4px;
  }
}
