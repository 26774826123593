.container {
  margin-bottom: 8px;
  padding: 0 24px;
  display: flex;
  height: 60px;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
}

.column {
  display: flex;
  flex: 1;
  align-items: center;

  &--content_end {
    justify-content: flex-end;
  }
}

.link {
  margin-right: 24px;

  &:last-child {
    margin: 0;
  }
}

.button_remove,
.button_export,
.button_print {
  margin-right: 24px;
  padding: 0;
  font-size: 14px;
  line-height: 1.29;
  cursor: pointer;
  color: #2b87db;
  background: none;
  border: none;
  outline: none;
  transition: color 0.15s;

  &:hover {
    color: #1a62a6;
  }
}

.popup_export {
  padding: 16px;

  a {
    margin-bottom: 8px;
    display: inline-block;

    &:last-child {
      margin: 0;
    }
  }
}

.vas {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;

  a {
    margin-left: 24px;
    padding: 3px 16px 5px;
    font-size: 14px;
    line-height: 1.43;
    text-decoration: none;
    color: white;
    background-color: #ff9d00;
    border-radius: 42px;
    transition: background-color 0.15s;

    &:hover {
      background-color: #af6800;
    }
  }
}
