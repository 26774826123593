.price {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.relevance:not(:empty) {
  margin-bottom: 8px;
}

.amount {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.history {
  margin-right: 0;
}

.labels {
  margin-top: 2px;
}

.discount {
  flex-basis: 100%;

  &.inline {
    flex-basis: auto;
  }
}
