.link {
  font-size: 14px;
  line-height: 1.43;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.15s;

  &:hover {
    color: #1a62a6;
  }
  &.without-pointer {
    cursor: default;
  }
}

@media print {
  .link {
    color: #121212;
  }
}
