.container {
  width: 540px;
}

.content {
  margin-top: 16px;
  max-width: 460px;
}

.link-container {
  position: relative;
  margin-top: 10px;
}

.popup_link {
  position: absolute;
  top: -32px;
  background-color: #121212;
  opacity: 0.9;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 12px;
}

.title_link {
  font-size: 12px;
  margin-top: 22px;
}

.input_link {
  margin-bottom: 4px;
  padding: 3px 0 5px 8px;
  display: inline-block;
  width: 320px;
  color: #121212;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
.input_link:selection {
  background-color: rgba(43, 135, 219, 0.2);
}

.input_link:disabled {
  background-color: white;
}

.copy_link {
  padding: 4px 16px;
  cursor: pointer;
  color: #2b87db;
  background-color: rgba(43, 135, 219, 0.1);
  border: none;
  border-radius: 42px;
  outline: none;
  transition: background-color 0.2s ease;
  display: inline-block;
  margin-left: 8px;
  font-weight: bold;
}

.copy_link:hover {
  background-color: rgba(43, 135, 219, 0.15);
}
