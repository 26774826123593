.container {
  padding: 0 0 24px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

@media print {
  .title {
    margin-top: 18px;
    font-size: 21px;
    line-height: 25px;
  }

  .container {
    a {
      font-size: 21px;
      line-height: 25px;
    }
  }
}
