.container {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
}

.element {
  margin-right: 4px;
  display: inline-block;
}
