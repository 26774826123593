.container {
  position: relative;
  height: var(--lineHeight_16px);
  line-height: var(--lineHeight_16px);
}

.marquee {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.text {
  display: inline-block;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
}
