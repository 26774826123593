.review {
  display: flex;
  align-items: center;
  gap: 4px;
}

.review-rate {
  color: var(--accent-warning-primary);
}

.review-text {
  padding: 4px 0;
  color: var(--text-secondary-default);
}
