.container {
  display: flex;
  margin: 0 9px 23px;
}

.logo-block {
  margin-right: 16px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.title-block a {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: var(--accent-main-primary);
  text-decoration: none;
}

.title-block a:hover {
  color: var(--control-main-primary-hovered);
  text-decoration: none;
}
