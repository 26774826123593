.container {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  background: var(--decorative-theme-white);
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.text-wrapper {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.icon-wrapper {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.title {
  margin: 0;
  max-width: 220px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--decorative-theme-dark);
}
