.segment {
  position: absolute;
  z-index: 101;
  top: 35%;
  left: 35%;
  background-color: white;
  border-radius: 4px;
}

.body {
  font-size: 16px;
  font-weight: normal;
  color: #121212;
}

.close_icon {
  position: absolute;
  top: 12px;
  right: 16px;
  color: #c9c9c9;
  cursor: pointer;
}

.close_icon:hover {
  color: #121212;
}

.question_block {
  margin: 16px 0 0;
}

.actions {
  display: flex;
  margin: 16px 0 0;
}

.actions .action_button:not(:first-child) {
  margin: 0 0 0 12px;
}

button .button_text {
  margin: 0 0 0 6px;
}

button .button_content {
  display: flex;
}

.button_content svg {
  align-self: center;
}
