.input {
  display: flex;
  flex-direction: column;
  gap: var(--unit_2);
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--unit_6);
  width: 370px;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: var(--unit_2);
  padding-top: var(--unit_4);
}

.controls {
  display: flex;
  justify-content: end;
}

.agreement-link {
  color: var(--text-main-default);
  text-decoration: none;
}
