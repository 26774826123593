.block {
  padding: 20px 24px 24px;
  border-top: 1px solid #e4e4e4;
  background: white;
}

.badge-wrap {
  display: flex;
  margin-bottom: 8px;
}

.title {
  font-size: 12px;
  color: var(--accent-negative-primary);
}

.title-icon {
  margin-right: 5px;
  margin-left: 20px;
}

.badge {
  color: #2e9e00;
  background-color: rgba(46, 158, 0, 0.1);
  padding: 0 6px;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  user-select: none;
  border-radius: 2px;
}

.list-item {
  display: flex;
  margin: 6px 0;
  font-size: 16px;
  line-height: 26px;
  color: var(--decorative-theme-dark);
}

.list-icon {
  margin-top: 2px;
  margin-right: 5px;
}
