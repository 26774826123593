@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.span-columns {
  grid-column: 1 / span 2;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.label-invalid {
  color: var(--control-negative-primary-hovered);
  .link {
    color: var(--control-negative-primary-hovered);
  }
}

.link {
  color: currentcolor;
}
