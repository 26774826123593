.root {
  position: relative;
  height: 150px;
}

.image {
  border-radius: 4px;
  border: 1px solid #f1f2f4;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.labels {
  position: absolute;
  top: 12px;
  left: 12px;
}

.label {
  padding: 0 4px 0 8px;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  line-height: 100%;

  &:not(:last-child) {
    margin-right: 4px;
  }
}

.label-count {
  font-size: 12px;
  margin-left: 4px;
}
