.container {
  margin: 0;
}

.promo {
  padding: var(--unit_3) var(--unit_4);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--surface-neutral, #f3f6ff);
}

.icon {
  margin-right: var(--unit_3);
  width: 24px;
  height: 24px;
}
