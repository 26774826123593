.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.form {
  margin-top: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.input {
  margin-right: 8px;
  width: 100%;
}
