.container {
  width: 540px;
}

.error {
  margin-left: 16px;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  line-height: 20px;
  color: #e10000;
}

.options {
  margin: 20px 0 30px;
}

.review-container {
  display: flex;
  margin: 20px 0 30px;
  background-color: #e8f3fb;
  border-radius: 2px;
  padding: 17px 20px;
}

.description {
  padding-left: 22px;
}

.icon-group-block {
  width: 79px;
  height: 51px;
  flex: 0 0 79px;
  margin-left: 40px;
}
