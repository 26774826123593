.content {
  padding: var(--unit_4) var(--unit_10) var(--unit_10);
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: column;
  gap: var(--unit_1);
}

.features-list {
  display: flex;
}

.features-list {
  margin-top: var(--unit_5);
}

.feature {
  flex: none;
}

.feature:not(:last-child) {
  margin-right: var(--unit_10);
}

.description {
  margin-top: var(--unit_5);
}

.banks {
  margin-top: var(--unit_6);
}

.author-description {
  margin-top: var(--unit_4);
}

.banks-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bank-item {
  display: block;
}

.bank-item {
  margin-top: var(--unit_5);
}

.banks-title {
  margin-top: var(--unit_5);
}

.controls {
  margin-top: var(--unit_6);
  display: flex;
  justify-content: end;
}
