.container {
  padding: 6px 0;
  display: flex;
  flex-direction: column;
}

.link {
  margin-top: 4px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  color: var(--current_color);
}

.icon {
  padding-left: 4px;
  display: flex;
  transform: scale(0.8);
}
