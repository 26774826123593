.container {
  margin-top: 16px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
}

.tab {
  position: relative;
  margin-right: 24px;
  display: inline-flex;
  align-items: center;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  cursor: pointer;
  color: #7a7a7a;

  &:hover {
    color: #121212;
  }

  svg {
    position: absolute;
    left: 0;
  }

  &:hover svg {
    color: #121212;
  }
}

.onmap {
  padding-left: 24px;
  order: 1;
}

.panorama {
  padding-left: 32px;
  order: 3;
}

.similar {
  padding-left: 23px;
  order: 1;
}

.tab.active {
  cursor: default;
  color: #121212;

  svg {
    color: #121212;
  }
}

.special {
  img {
    margin-right: 4px;
    vertical-align: middle;
  }
}

.special-promo {
  white-space: nowrap;

  a {
    display: inline-flex;
    line-height: 22px;
  }

  img {
    margin-right: 6px;
    width: 22px;
    height: 22px;
  }
}

@media print {
  .container {
    display: none;
  }
}
