.root {
  display: block;
  height: 100%;
  padding: 12px 12px 16px;
  border: 1px solid #e8e9ec;
  border-radius: 8px;
  background: white;
  text-decoration: none;
  color: inherit;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0.25, 0, 1);
  transition-property: transform, box-shadow;

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
  }
}

.row:not(:last-child) {
  margin-bottom: 8px;
}

.price {
  font-weight: bold;
  font-size: 16px;
}
