.container {
  display: block;
}
.content {
  overflow: hidden;
  transition:
    max-height 0.5s ease,
    min-height 0.5s ease,
    height 0.5s ease;
}
.layout {
  display: flex;
}

.toggle {
  padding-top: 12px;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.toggle:hover [data-mark='Icon'],
.toggle:hover [data-mark='Text'] {
  color: #256ba5;
}

@media print {
  .content {
    overflow: visible;
    /* Для перезаписи инлайн стилей в компоненте */
    /* stylelint-disable declaration-no-important */
    max-height: none !important;
  }

  .toggle {
    display: none;
  }
}
