.link {
  text-decoration: none;
  height: 100%;
}

.container {
  display: flex;
  box-sizing: border-box;
  min-height: 100%;
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: var(--unit_1);
  flex-flow: column;
}

.images {
  position: relative;
  height: 120px;
  min-height: 120px;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: var(--unit_2);
  right: var(--unit_2);
  border-radius: 2px;
  width: var(--unit_8);
  height: var(--unit_8);
}

.content {
  padding: var(--unit_2) var(--unit_4);
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.price {
  margin: var(--unit_1) 0;
}

.footer {
  margin: auto 0 0;
  padding: var(--unit_2) 0 0;
}

.advertiser {
  position: relative;
  margin-top: var(--unit_1);
  color: var(--black_40);
}

.advertiser::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  pointer-events: none;
  content: '';
  background: linear-gradient(90deg, transparent 0%, var(--decorative-theme-white) 100%);
}
