.container {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 17px 12px;
  max-width: 394px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 741px) {
    min-width: 394px;
  }
}
.sms-container {
  display: grid;
  grid-template-columns: 160px auto;
  grid-gap: 12px;
  align-items: end;
}
.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
}
.description {
  font-size: 16px;
  line-height: 20px;
}
.label-comment {
  font-size: 14px;
  line-height: 1.43;
  color: #2b87db;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    pointer-events: none;
  }
}
.comment-text {
  margin-right: 8px;
}
.textarea {
  padding: 4px 8px 8px;
  width: 100%;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;
  resize: none;

  &:focus {
    border-color: #121212;
  }
  &:hover {
    border-color: #7a7a7a;
  }
}
.label {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 8px;
}
.span-columns {
  grid-column: 1 / span 2;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.child-types-container {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;
}
.submit {
  display: flex;
  justify-content: flex-end;
  &:not(:only-child) {
    margin-left: 12px;
  }
}
.submit-container {
  flex-direction: row;
  justify-content: flex-end;
}
.error {
  font-size: 14px;
  line-height: 1.43;
  color: #ff5050;
  white-space: pre-line;
  flex-grow: 1;
}

.phone-input-container {
  position: relative;
}

.sms-code {
  display: flex;
  align-items: flex-end;
  max-height: 28px;
  :first-child {
    max-width: 65px;
    margin-right: 12px;
  }
}
.seconds-left {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: -3px;
  color: #7a7a7a;
}
.send-code-again {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 3px;
  color: #2b87db;
  cursor: pointer;
}
.term-description {
  font-size: 11px;
  line-height: 1.43;
  color: #7a7a7a;
}
.label-invalid {
  color: #ff5050;
  .link {
    color: #ff5050;
  }
}
.link {
  color: #7a7a7a;
}
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}
