.price {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.amount {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
}

.coworking-additional-info {
  margin-top: 8px;
}

.history {
  margin-right: 8px;
}
