.container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
  height: 100%;
}
