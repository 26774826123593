.container {
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: 8px;
  }
}
.entry-field {
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 8px;
  }
}
.info {
  margin-bottom: 8px;
}
.button {
  display: flex;
  justify-content: flex-end;
}
.title {
  margin-top: 0;
  margin-bottom: 8px;
}
.close {
  padding: 0;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.15s;

  &:hover {
    color: #121212;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
