.overlay {
  z-index: 10000;
  display: flex;
  background-color: rgba(24, 41, 48, 0.8);
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  top: 12px;
  right: 15px;
  padding: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: 0.15s color;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: #121212;
  }
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: #121212;
}

.form {
  margin-top: 18px;
  display: block;
}

.options-container {
  display: flex;
}

.options {
  flex: 0 0 auto;
}

.options:not(:last-child) {
  margin-right: 86px;
}

.actions {
  margin-top: 32px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
}
