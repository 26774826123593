/* stylelint-disable */
:global {
  .similar-offers-slider .slick-slide {
    padding: 0 8px;
  }

  .similar-offers-slider .slick-prev,
  .similar-offers-slider .slick-next {
    position: absolute;
    z-index: 1;
    top: 50%;
    padding: 0;
    display: flex;
    width: 40px;
    height: 40px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    outline: none;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    background: var(--white_100, white);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
    transition: all 0.2s;
  }

  .similar-offers-slider .slick-prev {
    left: 0;
    transform: translate(calc(-50% + 8px), -50%);
  }

  .similar-offers-slider .slick-next {
    right: 0;
    transform: translate(calc(50% - 8px), -50%);
  }

  .similar-offers-slider .slick-track {
    display: flex;
    margin-left: 0;
  }

  .similar-offers-slider .slick-track .slick-slide {
    height: auto;
  }

  .similar-offers-slider .slick-track .slick-slide > div {
    height: 100%;
  }
}
