.element {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: var(--accent-main-primary);
  font-size: 16px;
}

.element:hover {
  color: var(--accent-main-primary);
}
