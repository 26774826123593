.container {
  display: flex;
  flex-direction: column;
  gap: var(--unit_6);
  width: 370px;
  padding-top: var(--unit_10);
}

.content {
  padding: var(--unit_6) 0;
  display: flex;
  flex-direction: column;
  gap: var(--unit_8);
}

.message {
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
}

.image-container {
  margin: auto;
}

.controls {
  margin: auto;
}
