.highway {
  margin-right: 24px;
  margin-bottom: 8px;
  display: inline-flex;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  flex-grow: 1;
  flex-shrink: 0;
}

.highway:last-child {
  margin-right: 0;
}

.highway_distance {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--gray60_100);
}

.highway_link {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: var(--decorative-theme-dark);
}
.highway_link:hover {
  color: var(--accent-main-primary);
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
