.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.container a {
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline;
}

.left {
  flex: 1 1;
}

.title {
  margin-bottom: 8px;
}

.stats {
  margin-bottom: 24px;
  display: flex;
  flex-flow: row nowrap;
}

.stats .item + .item {
  margin-left: 24px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
}

.item span:first-child {
  margin-right: 4px;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:empty {
    display: none;
  }
}

.buttons .button + .button {
  margin-left: var(--unit_5);
}

.logo {
  width: 80px;
  height: 80px;
  border: 0.56px solid var(--gray10_100);
  border-radius: 4px;
  margin-left: 16px;
  background: center no-repeat;
  background-size: cover;
}

.phones-opened-info {
  margin-top: var(--unit_6);
}
