.container {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 17px 12px;
  max-width: 394px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 741px) {
    min-width: 394px;
  }
}

.buttons-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;
}
