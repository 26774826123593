.item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0;
}

.item + .item {
  box-shadow: 0 -1px 0 var(--gray10_100);
}

.item p + p {
  margin-left: 24px;
  text-align: right;
  white-space: pre-line;
}

.group {
  width: 100%;
}

.group-commercial {
  margin: 64px 0;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.right {
  margin-left: 40px;
}

.header {
  margin-bottom: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
