.wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  margin-top: 8px;
  padding: var(--unit_6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: var(--white_100);
}
