.container {
  margin-bottom: 12px;
  padding: 12px 16px 20px;
  background-color: rgba(21, 34, 66, 0.06);
  border-radius: 4px;
}

.title {
  margin-bottom: 4px;
}

.text {
  margin-bottom: 12px;
}
