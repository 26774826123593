.icon,
.logo {
  width: 56px;
  height: 56px;
}

.logo {
  object-fit: contain;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
