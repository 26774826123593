.widget-container {
  font-size: 14px;
}

.container {
  position: relative;
  margin-right: 12px;
  display: inline-block;
  font-weight: normal;
  line-height: 1;
}

.container.without-margins {
  margin: 0;
}

.container:hover .popup-container {
  z-index: 9999;
  visibility: visible;
  pointer-events: initial;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    visibility 0s,
    z-index 0s;
}

.icon {
  display: flex;
}

.container:hover .icon {
  z-index: 1;
}

.popup-container {
  position: absolute;
  z-index: -1;
  top: 0;
  padding-top: 36px;
  visibility: hidden;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0s 0.3s,
    z-index 0s 0.3s;

  &.popup-container--left {
    left: 100%;
    transform: translateX(calc(-100% + 16px));
  }

  &.popup-container--right {
    right: 100%;
    transform: translateX(calc(100% - 12px));
  }

  &.popup-container--center {
    right: 50%;
    transform: translateX(50%);
  }

  &.popup-container--left .popup::after {
    right: 0;
    transform: translateX(-15px) rotate(45deg);
  }

  &.popup-container--right .popup::after {
    left: 0;
    transform: translateX(15px) rotate(45deg);
  }

  &.popup-container--center .popup::after {
    transform: translateX(-50%) rotate(45deg);
  }
}

.popup {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.popup-content {
  position: relative;
  z-index: 1;
  background-color: white;
  background-origin: border-box;
  border-radius: 4px;
}
