.caption,
.price {
  display: flex;
  align-items: center;
}

.caption {
  margin-top: 4px;
}

.price_tooltip {
  margin-left: 12px;
}

.caption_tooltip {
  margin-left: 4px;
}
