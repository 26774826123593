.container {
  margin-bottom: 8px;
  display: flex;
  height: 98px;
  color: white;
  border: solid 1px #e4e4e4;
  border-radius: 2px;
  align-items: center;
}

.gray {
  background-color: rgba(21, 34, 66, 0.4);
}

.red {
  background-color: #ed6d6d;
}

.green {
  background-color: #2e9e00;
}

.messages_container {
  display: inline-block;
}

.warning_title_with_icon {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
}

.warning_title_with_icon::before {
  margin-right: 12px;
  margin-left: 20px;
  display: inline-block;
  width: 14px;
  height: 13px;
  content: '';
  background-image: url('./assets/icn-alarm.svg');
  background-repeat: no-repeat;
}

.warning_title {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  margin-left: 20px;
}

.warning_info {
  margin-left: 46px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: white;
}

.warning_info_link {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.complaint {
  position: absolute;
  z-index: 1000;
  top: -5px;
  left: calc(100% + 10px);
  text-align: left;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
}

.complaint::before,
.complaint::after {
  position: absolute;
  top: 9px;
  left: -4px;
  width: 8px;
  height: 8px;
  content: '';
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  transform: rotate(130deg);
}

.tooltip-wrapper {
  position: relative;
}
