.label {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.label span {
  margin-left: 4px;
}

.logo {
  padding-top: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo a {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.logo a:hover,
.logo a:active {
  color: var(--accent-main-primary);
  text-decoration: none;
}

.img {
  width: 72px;
  height: 72px;
  border: 0.56px solid var(--gray10_100);
  border-radius: 4px;
  margin-bottom: 16px;
  background: center no-repeat;
  background-size: contain;
}

.builder {
  all: unset;
  cursor: pointer;
}
