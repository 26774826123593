.title {
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: var(--gray60_100);
}

.title a {
  text-decoration: none;
  color: var(--gray60_100);
  cursor: pointer;
}

.title a:hover {
  color: var(--accent-main-primary);
}
