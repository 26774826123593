.root {
  display: flex;
}

.avatar {
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #f1f2f4;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 8px;
}

.avatar-none {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f1f2f4;
  width: 40px;
  height: 40px;
  background: #e8e9ec;
  margin-right: 8px;

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.info {
  overflow: hidden;
}

.title {
  display: flex;
}

.name {
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.account-type {
  font-size: 12px;
  line-height: 18px;
  color: #737a8e;
}

.icon {
  margin-left: 4px;
}
