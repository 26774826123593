.links {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.link-item + .link-item {
  margin-left: 26px;
}

.link-item a {
  text-decoration: none;
  position: relative;
  padding: 16px 0;
  display: block;
}

.link-item a::after {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 3px;
  content: '';
  background-color: transparent;
  border-radius: 1px;
  transition: background-color 0.15s;
}

.active a::after {
  background-color: var(--accent-main-primary);
}

.social-links {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.vk,
.ok {
  display: inline-block;
  cursor: pointer;
  transition: color 0.15s;

  svg {
    width: 32px;
    height: 32px;
  }
}

.vk + .ok {
  margin-left: 15px;
}

.vk {
  color: #526e8f;

  &:hover {
    color: #445d75;
  }
}

.ok {
  color: #ec7c21;

  &:hover {
    color: #d16b20;
  }
}

.input-link {
  padding: 3px 0 5px 8px;
}
