.container {
  top: 8px;
  transition: top 0.25s;
  z-index: 1;

  &--sticky {
    position: sticky;
  }

  &--relative {
    position: relative;
  }

  &--hidden {
    animation: hideContainerAnimation 0.5s ease 0s 1 normal forwards;
  }

  &--not-hidden {
    animation: showContainerAnimation 0.5s ease 0s 1 normal forwards;
  }

  &--active {
    top: 60px;
  }

  .aside_banner {
    margin-top: 8px;
  }

  @media print {
    position: absolute;
    top: 0;
    left: calc(65% + 8px);
  }
}

@media print {
  .aside_banner {
    display: none;
  }
}

@keyframes hideContainerAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showContainerAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
