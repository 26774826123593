.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: var(--unit_2) var(--unit_3);
  border-radius: var(--unit_1);
  border: 1px solid #c9d1e5;
  margin-bottom: var(--unit_4);
  cursor: pointer;
  user-select: none;

  &.disabled {
    background: var(--gray6_100);
    border-color: var(--gray10_100);
    pointer-events: none;
    user-select: none;
  }

  &.active:not(.disabled) {
    border: 1px solid var(--solid-blue500);
    box-shadow: 0 0 0 1px var(--focused_15);
  }
}

.icon,
.chevron {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  flex-shrink: 1;
  width: 100%;
  padding: 0 var(--unit_3);

  span {
    color: var(--black_100);
  }
}
