.popup-link {
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.4);
  color: white;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}
