.container {
  position: relative;
  display: inline-block;

  svg {
    color: var(--icon-secondary-default);
  }
}

.icon {
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-image: url('assets/download.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.popup {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -10px;
  display: none;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition:
    opacity 0.2s ease,
    visibility 0s 0.2s;
  transform: translateX(100%);

  &.popup--open {
    display: block;
    visibility: visible;
    opacity: 1;
    transition:
      opacity 0.2s ease,
      visibility 0s;
  }

  &::after {
    position: absolute;
    top: 16px;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transform: translateX(-50%) rotate(312deg);
  }
}

.link-container {
  position: relative;
  z-index: 1;
  padding: 16px 24px 16px 16px;
  background-color: white;
  background-origin: padding-box;
}

.link {
  display: block;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }

  & + & {
    margin-top: 16px;
  }
}
