/* stylelint-disable-next-line */
:global(#imap-hint-address) {
  margin-top: 24px;
  padding: 14px 16px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--decorative-theme-dark);
  background: white;
  border: 1px solid #f1f2f4;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.container {
  position: relative;
  background: #f1f2f3;
  width: calc(100vw - 128px);
  height: calc(100vh - 156px);
  min-width: 824px;
}

.panorama {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
