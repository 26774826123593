.container {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;

  & > * {
    margin-right: 8px;
  }
}

@media print {
  .container {
    display: none;
  }
}
