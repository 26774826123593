.container button {
  margin-top: 10px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  color: white;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 4px;
  outline: none;
}

.message {
  overflow: hidden;
  white-space: nowrap;

  div {
    display: flex;
  }

  .name {
    cursor: pointer;
    border-bottom: 1px solid white;
  }
}
