.banks {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -8px;
}

.bank {
  width: 25%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 8px;
}

.icon {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--decorative-theme-white) no-repeat center center;
  background-size: 40px;
  margin-right: 8px;
  filter: drop-shadow(0 1.74px 20.08px rgba(0, 0, 0, 0.1));
}
