.show-phone {
  background-color: var(--accent-main-primary);
  color: var(--decorative-theme-white);
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.show-phone:hover {
  background-color: var(--control-main-primary-hovered);
}

.show-phone:focus {
  box-shadow: 0 0 1px 1px var(--stroke-control-focused);
}

.show-phone:active {
  background-color: var(--control-main-primary-pressed);
}

.show-phone-xs {
  height: 28px;
  padding: 3px 11px;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
}

.show-phone-m {
  height: 40px;
  padding: 8px 15px;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
}

.phone {
  margin-top: 8px;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: #182930;
}

.phone:first-child {
  margin-top: 0;
}

.phone-size-xs {
  font-size: 16px;
  line-height: 1.38;
}

.phone-size-m {
  font-size: 28px;
  line-height: 1.21;
}
