.container {
  display: inline-block;
}

.vas_item {
  position: relative;
  margin-left: 16px;
  padding-left: 20px;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  vertical-align: middle;
  text-decoration: none;
  color: #ff8400;
  transition: color 0.15s;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    transform: translateY(-50%);
  }

  &:hover {
    color: #af6800;
  }
}
