.name_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--unit_2);
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_24px);
  color: var(--dark_100);
}

a.name {
  cursor: pointer;
  text-decoration: none;
}

a.name:hover {
  color: var(--accent-main-primary);
}

.honest_brand {
  margin-left: var(--unit_1);
  height: 16px;
  width: 16px;
}
