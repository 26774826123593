.underground {
  margin-right: var(--unit_6);
  margin-bottom: var(--unit_1);
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  flex-shrink: 0;
  align-items: center;
}

.underground:last-child {
  margin-right: 0;
}

.underground_time {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--gray60_100);
  flex-grow: 1;
  flex-shrink: 0;
}

.underground_link {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: var(--decorative-theme-dark);
}

.underground_link:hover {
  color: var(--accent-main-primary);
}

.under_construction {
  color: var(--gray60_100);
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
