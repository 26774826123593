.calltracking_badge {
  margin-top: 12px;
  margin-bottom: 4px;
  width: 278px;
  padding: 8px 10px 10px;
  background-color: rgba(46, 158, 0, 0.07);
  border: 1px solid rgba(46, 158, 0, 0.1);
  border-radius: 2px;
}

.calltracking_badge-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
  position: relative;
  margin-left: 25px;

  &::before {
    position: absolute;
    background-image: url('./icon/phone.svg');
    top: 3px;
    left: -25px;
    content: '';
    width: 15px;
    height: 22px;
  }
}

.calltracking_badge-description {
  font-size: 12px;
  line-height: 16px;
  color: rgba(18, 18, 18, 0.65);
  margin-left: 25px;
  margin-top: 3px;
  font-weight: normal;
}
