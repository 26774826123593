.container {
  margin-top: 20px;
  display: none;
  width: 100%;
  page-break-before: always;
}

.photo {
  max-width: 100%;
  page-break-inside: avoid;
}

.cell {
  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-left: 10px;
  }

  &:only-child {
    padding: 0;
  }
}

@media print {
  .container {
    display: block;
  }
}
