.seo-block {
  margin-bottom: 24px;
}

.seo-block:last-child {
  margin-bottom: 0;
}

.links {
  padding-top: 16px;
}
