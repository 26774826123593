.container {
  padding-top: 8px;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.link {
  display: inline;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-decoration: none;
  cursor: pointer;
  color: var(--accent-main-primary);
  transition: color 0.15s;
}

.link:hover {
  color: var(--control-main-primary-hovered);
}

@media print {
  .link {
    color: var(--decorative-theme-dark);
  }

  .container {
    font-size: 21px;
    line-height: 25px;
  }
}
