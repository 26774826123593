.head {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  color: black;
}

.title {
  margin-bottom: 16px;
}

.close {
  position: absolute;
  top: -14px;
  right: -14px;
  padding: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.15s;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: #121212;
  }
}

.body {
  display: flex;

  button:first-child {
    margin-right: 8px;
  }
}

.error {
  margin-bottom: 16px;
  padding: 0 39px 0 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
  background-color: rgba(255, 80, 80, 0.1);
  transition: height 0.15s;

  &--visible {
    height: 54px;
  }

  &--hide {
    margin-bottom: 0;
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87px;
}
