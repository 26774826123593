.list {
  margin: 0;
  padding: 5px 15px 0;
  display: block;
}

.item {
  position: relative;
  margin: 13px 0;
  margin-left: 12px;
  padding-left: 20px;
  display: block;
  font-size: 12px;
  line-height: 18px;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    display: block;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    content: '';
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-radius: 50%;
  }

  &:last-child:not(.more) {
    margin-right: 12px;
  }
}

.close {
  padding: 0;
  display: block;
  width: 100%;
  line-height: 37px;
  text-align: center;
  cursor: pointer;
  color: #2b87db;
  border: none;
  border-top: 1px solid var(--decorative-subway-msk-kommunarskaya);
  border-radius: 0;
  outline: none;
  transition: color 0.15s;

  &:hover {
    color: #1a62a6;
  }
}
