.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  text-decoration: none;
  background-color: white;
  border: 1px solid var(--stroke-border-default);
  border-radius: var(--unit_2, 8px);
}

.photo-block {
  position: relative;
  background: var(--decorative-theme-white) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.photo-label,
.annotation {
  position: absolute;
  z-index: 1;
}

.photo-label {
  bottom: var(--unit_4, 16px);
  right: var(--unit_4, 16px);
}

.annotation {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.content {
  padding: var(--unit_6, 24px) var(--unit_6, 24px) var(--unit_4, 16px);
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_2, 8px);
  min-height: 100px;
  border-bottom: 1px solid var(--stroke-border-default);
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_2, 8px);
}

.logo-container {
  display: flex;
  width: fit-content;
  height: fit-content;
  border: 1px solid var(--stroke-border-default);
  border-radius: var(--unit_1, 4px);
}
