.container {
  padding: 24px 30px 35px;
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: black;
}

.preloader-wrapper {
  position: absolute;
  z-index: 100;
  inset: 0;
  display: flex;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 14px;
  justify-content: center;
  align-items: center;
}
