.container {
  position: relative;
}

div.popup {
  padding: 16px;
  min-width: 208px;
}

.social_row {
  margin-bottom: 16px;
  line-height: 0;
  display: flex;
  justify-content: center;

  & > div {
    margin-right: 15px;
  }

  & > div:last-child {
    margin-right: 0;
  }
}

.vk,
.ok {
  display: inline-block;
  cursor: pointer;
  transition: color 0.15s;

  svg {
    width: 32px;
    height: 32px;
  }
}

.vk {
  color: #526e8f;

  &:hover {
    color: #445d75;
  }
}

.ok {
  color: #ec7c21;

  &:hover {
    color: #d16b20;
  }
}

.text {
  margin-bottom: 4px;
  padding: 3px 0 5px 8px;
  display: inline-block;
  width: 100%;
  color: #121212;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;

  &::selection {
    background-color: rgba(43, 135, 219, 0.2);
  }
}

.copy_link {
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.15s;

  &:hover {
    color: #256ba5;
  }
}
