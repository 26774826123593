.container {
  display: flex;
  flex-direction: column;
  margin-top: var(--unit_4);

  & > :nth-child(2) {
    margin-top: var(--unit_6);
  }

  & > :nth-child(3) {
    margin-top: var(--unit_3);
  }

  & > :nth-child(4) {
    margin-top: var(--unit_6);
    width: fit-content;
  }
}

.image {
  width: 100%;
  border-radius: var(--unit_3);
}

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--unit_2);
  list-style-type: none;
}

.item {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.buttons {
  display: flex;
  gap: var(--unit_2);
}
