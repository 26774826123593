.container {
  padding: 0 18px;
  display: none;
}

.photo {
  width: 100%;
}

@media print {
  .container {
    display: block;
  }
}
