.actual {
  margin: 8px 0 12px;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
  display: flex;
}

.description {
  max-width: 325px;
}

.link {
  margin-top: 12px;
  display: inline-block;
  color: var(--accent-main-primary);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.1s;
}

.link:focus,
.link:hover {
  color: var(--control-main-primary-hovered);
}
