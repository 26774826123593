.description {
  margin-top: 4px;
}

.prices_block {
  margin-top: 24px;
}

.chart_filters {
  margin-top: 32px;
}

.chart_wrapper {
  margin-top: 15px;
}

.link_btn:not(:empty) {
  margin-top: 17px;
}
