.logo a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  gap: var(--unit_4);
}

.logo a:hover,
.logo a:active {
  color: var(--accent-main-primary);
  text-decoration: none;
}

.title {
  display: flex;
  flex-flow: column nowrap;
}
