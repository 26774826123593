.container {
  margin-bottom: 8px;
  overflow: hidden;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  transition: height 0.15s ease 0.15s;

  &--hide {
    margin-bottom: 0;
    /**
    * !important потому что начальная высота блока, к которому применяются стили,
    * устанавливается динамически с помощью inline styles.
    */
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
    border: none;
  }
}

.content {
  padding: 24px 24px 24px 50px;
}

.title {
  position: relative;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #ff5050;

  svg {
    position: absolute;
    top: 3px;
    left: -26px;
    width: 14px;
    height: 14px;
  }
}

.description {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.38;
  color: #121212;
}

.fix_wrapper button {
  margin-right: 16px;
}

.link_fix {
  padding: 3px 16px 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-decoration: none;
  color: white;
  background-color: #2b87db;
  border-radius: 42px;
  transition: background-color 0.15s;

  &:hover {
    background-color: #256ba5;
  }
}

.fix_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user_confirmed_fix {
  font-weight: bold;
}
