.container {
  padding: var(--unit_1) var(--unit_1) var(--unit_3);
}

.button {
  cursor: pointer;
  padding: var(--unit_1) var(--unit_3) var(--unit_1) var(--unit_2);
  display: flex;
  gap: var(--unit_2);
  align-items: center;
  width: max-content;
  background-color: var(--control-main-secondary-default);
  border-radius: 16px;
  box-shadow: 0 4px 12px 0 #00000014;
}

.button:hover {
  background-color: var(--control-main-secondary-hovered);
}
