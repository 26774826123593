.block {
  padding: 20px 24px 24px;
  border-top: 1px solid #e4e4e4;
  background: white;
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.43;
  margin: 8px 0;
}

.badge {
  color: #2e9e00;
  background-color: rgba(46, 158, 0, 0.1);
  padding: 0 6px;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  user-select: none;
  border-radius: 2px;
}

.link-wrapper {
  position: relative;
  display: inline-block;
}

.link-wrapper:hover > .tooltip {
  visibility: visible;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 24px;
  z-index: 110;
}

.terms {
  margin-top: 12px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #2b87db;
  transition: color 0.15s;

  &:hover {
    color: #1a62a6;
  }
}
