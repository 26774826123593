.container {
  margin: 24px 0 40px;
}

.container.disable-external-container-styles {
  margin: 0;
  border: none;
}

.empty {
  opacity: 0;
  width: 0;
  height: 0;
}

.title_block {
  margin: 0 9px 23px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: var(--decorative-theme-dark);
}
