.container {
  padding: 0 24px;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
}

.header-information {
  flex: 1 1 auto;
}

.top-line {
  display: flex;
}

@media print {
  .container {
    padding: 0 18px;
  }

  .header-information {
    padding-right: 0;
  }
}
