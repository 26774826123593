.container {
  margin: 8px 0;
  padding: 16px 24px 0;
  display: flex;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
}

.title {
  margin: 0;
  padding-right: 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  margin: 0 12px 16px;
  display: inline-block;
}
