.buttons_wrapper {
  margin-top: 20px;
  width: 100%;
  padding: 0 var(--unit_6);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_send_message {
  margin-left: 8px;
}

.phones_hint {
  margin-top: var(--unit_6);
}
