body {
  background-color: white;
}

/* stylelint-disable-next-line */
:global(#frontend-offer-card) {
  display: flex;
  background: white;
  flex: 1 1 auto;
}

.page {
  margin: auto;
  display: flex;
  width: 100%;
  min-width: 952px;
  max-width: 1360px;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  align-content: flex-start;
}

@media print {
  html,
  body {
    display: block;
    overflow: visible;
    width: 210mm;
  }

  .page {
    position: relative;
    display: block;
  }

  /* stylelint-disable-next-line */
  :global(#adaptive-header) {
    display: none;
  }

  /* stylelint-disable-next-line */
  :global(#header-frontend) {
    display: none;
  }

  /* stylelint-disable-next-line */
  :global(#frontend-offer-card) {
    display: block;
  }

  /* stylelint-disable-next-line */
  :global(#adfox-stretch-banner) {
    display: none;
  }
}
