@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 394px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 741px) {
    min-width: 394px;
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.sms-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  :first-child {
    max-width: 65px;
    margin-right: 12px;
  }
}

.buttons-wrap {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;
}

.seconds-left {
  font-size: 12px;
  line-height: 1.5;
  color: var(--gray60_100);
}

.send-code-again {
  font-size: 12px;
  line-height: 1.5;
  color: var(--accent-main-primary);
  cursor: pointer;
}
