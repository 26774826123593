.close {
  position: absolute;
  top: 1px;
  right: 2px;
  padding: 10px;
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: 0.15s color;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: #121212;
  }
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  color: #121212;
}

.actions {
  margin-top: 16px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
}
