.buttons-outer {
  display: flex;
  justify-content: center;
}

.complaint-outer {
  position: relative;
  margin-right: 8px;
  text-align: left;
}

.tooltip {
  position: absolute;
  z-index: 1000;
  top: 37px;
  left: -60px;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

  &::before,
  &::after {
    position: absolute;
    top: -4px;
    left: 120px;
    width: 8px;
    height: 8px;
    content: '';
    background-color: white;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    transform: rotate(-136deg);
    transform-origin: 50% 50%;
  }
}
