.container {
  width: 667px;
}

.subtitle {
  margin-top: 4px;
  margin-bottom: 24px;
  line-height: 20px;
}

.description {
  margin: 24px 0 33px;
  padding: 15px 20px;
  line-height: 20px;
  background-color: #fff1e0;
  border-left: 4px solid #ff9d00;
}

.footer {
  position: relative;
}

.archive {
  margin-right: 8px;
}

.cancel {
  position: absolute;
  right: 0;
}
