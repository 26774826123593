.block {
  position: relative;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: var(--unit_10);
  }
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
}

.block-title {
  display: block;
}

.section {
  margin-top: var(--unit_5);
}

.section-heading {
  padding-bottom: var(--unit_2);
  display: flex;
  border-bottom: 1px solid #c9d1e5;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.line {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: var(--unit_2);
  }
}

.line-label {
  span {
    color: #707a95;
  }
}

.line-value-link {
  text-decoration: none;
  color: var(--accent-main-primary);

  span {
    color: var(--accent-main-primary);
  }
}

.paragraph {
  margin-top: var(--unit_2);
}
