.container {
  display: inline-flex;
  align-items: center;
  flex-flow: row nowrap;
}

.rating {
  padding-right: 12px;
  flex: 0 0 auto;
  font-size: 38px;
  font-weight: bold;
  line-height: 44px;
  color: var(--accent-warning-primary);
}

.details {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}

.invisible {
  display: none;
}

.pointer {
  cursor: pointer;
}
