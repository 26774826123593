.container {
  background: var(--decorative-theme-white);
  border: 1px solid #c9d1e5;
  border-radius: 8px;
  overflow: hidden;
}

.container::before {
  content: '';
  display: block;
  height: 68px;
  background: #e6f0ff;
}

.content {
  margin-top: -40px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
