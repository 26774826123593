.phone ~ .phone {
  margin-top: var(--unit_2);
}

.phone-link {
  text-decoration: none;
  font-weight: bold;
  color: #182930;
}

.row {
  display: flex;

  & .phone {
    margin-top: 0;
  }

  & .phone ~ .phone::before {
    content: ', ';
  }
}

.phone-size-xxs {
  font-size: 14px;
  line-height: 20px;
}

.phone-size-xs {
  font-size: 16px;
  line-height: 1.38;
}

.phone-size-s {
  font-size: 22px;
  line-height: 1.21;
}

.phone-size-m {
  font-size: 28px;
  line-height: 1.21;
}

.phone-qrcode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
