.item {
  padding: 4px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: end;
}

.divider {
  margin: 6px;
  width: 100%;
  border-bottom: 1px dotted var(--gray40_100);
}

.item:last-child {
  padding-bottom: 0;
}
