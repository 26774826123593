.item {
  position: relative;
  display: inline-block;
  padding: 12px 26px;

  &::before {
    position: absolute;
    left: 0;
    width: 16px;
    height: 22px;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
  }

  &.atelier::before {
    background-image: url('./assets/ic-atelier.svg');
  }

  &.atm::before {
    background-image: url('./assets/ic-atm.svg');
  }

  &.automatic-gates::before {
    background-image: url('./assets/ic-automatic-gates.svg');
  }

  &.aquapark::before {
    background-image: url('./assets/ic-aquapark.svg');
  }

  &.baby-sitting::before {
    background-image: url('./assets/ic-baby-sitting.svg');
  }

  &.balcony::before {
    background-image: url('./assets/ic-balcony.svg');
  }

  &.bank-department::before {
    background-image: url('./assets/ic-bank-department.svg');
  }

  &.beauty-shop::before {
    background-image: url('./assets/ic-beauty-shop.svg');
  }

  &.banya::before {
    background-image: url('./assets/ic-wash.svg');
  }

  &.basement::before {
    background-image: url('./assets/ic-basement.svg');
  }

  &.bath::before {
    background-image: url('./assets/ic-wash.svg');
  }

  &.big-balcony::before {
    background-image: url('./assets/ic-big-balcony.svg');
  }

  &.bowling::before {
    background-image: url('./assets/ic-bowling.svg');
  }

  &.buffet::before {
    background-image: url('./assets/ic-buffet.svg');
  }

  &.cafe::before {
    background-image: url('./assets/ic-cafe.svg');
  }

  &.canal::before {
    background-image: url('./assets/ic-canal.svg');
  }

  &.car-service::before {
    background-image: url('./assets/ic-car-service.svg');
  }

  &.car-wash::before {
    background-image: url('./assets/ic-car-wash.svg');
  }

  &.central-reception::before {
    background-image: url('./assets/ic-central-reception.svg');
  }

  &.clothes-studio::before {
    background-image: url('./assets/ic-clothes-studio.svg');
  }

  &.cinema::before {
    background-image: url('./assets/ic-cinema.svg');
  }

  &.cargolift::before {
    background-image: url('./assets/ic-elevator.svg');
  }

  &.cond::before {
    background-image: url('./assets/ic-snow.svg');
  }

  &.conference-room::before {
    background-image: url('./assets/ic-conference-room.svg');
  }

  &.dishwasher::before {
    background-image: url('./assets/ic-washing.svg');
  }

  &.electricity::before {
    background-image: url('./assets/ic-electricity.svg');
  }

  &.exhibition-warehouse-complex::before {
    background-image: url('./assets/ic-exhibition-warehouse-complex.svg');
  }

  &.fitness-centre::before {
    background-image: url('./assets/ic-fitness-centre.svg');
  }

  &.fridge::before {
    background-image: url('./assets/ic-cold.svg');
  }

  &.furniture::before {
    background-image: url('./assets/ic-bed.svg');
  }

  &.game-room::before {
    background-image: url('./assets/ic-game-room.svg');
  }

  &.garage::before {
    background-image: url('./assets/ic-garage.svg');
  }

  &.gas::before {
    background-image: url('./assets/ic-gas.svg');
  }

  &.hotel::before {
    background-image: url('./assets/ic-hotel.svg');
  }

  &.internet::before {
    background-image: url('./assets/ic-internet.svg');
  }

  &.inspection-pit::before {
    background-image: url('./assets/ic-inspection-pit.svg');
  }

  &.kitchen::before {
    background-image: url('./assets/ic-kitchen.svg');
  }

  &.lift::before {
    background-image: url('./assets/ic-elevator.svg');
  }

  &.market::before {
    background-image: url('./assets/ic-market.svg');
  }

  &.notary-office::before {
    background-image: url('./assets/ic-notary-office.svg');
  }

  &.office-space::before {
    background-image: url('./assets/ic-office-space.svg');
  }

  &.park::before {
    background-image: url('./assets/ic-park.svg');
  }

  &.parking::before {
    background-image: url('./assets/ic-parking.svg');
  }

  &.pharmacy::before {
    background-image: url('./assets/ic-pharmacy.svg');
  }

  &.phone::before {
    background-image: url('./assets/ic-phone.svg');
  }

  &.photo-studio::before {
    background-image: url('./assets/ic-photo-studio.svg');
  }

  &.pool::before {
    background-image: url('./assets/ic-pool.svg');
  }

  &.radiator::before {
    background-image: url('./assets/ic-radiator.svg');
  }

  &.rink::before {
    background-image: url('./assets/ic-rink.svg');
  }

  &.secure::before {
    background-image: url('./assets/ic-24-secure.svg');
  }

  &.shower::before {
    background-image: url('./assets/ic-wash.svg');
  }

  &.slot-machines::before {
    background-image: url('./assets/ic-slot-machines.svg');
  }

  &.tire::before {
    background-image: url('./assets/ic-tire.svg');
  }

  &.toilet::before {
    background-image: url('./assets/ic-toilet.svg');
  }

  &.tv::before {
    background-image: url('./assets/ic-tv.svg');
  }

  &.warehouse::before {
    background-image: url('./assets/ic-warehouse.svg');
  }

  &.washing::before {
    background-image: url('./assets/ic-washing.svg');
  }

  &.water::before {
    background-image: url('./assets/ic-water.svg');
  }

  &.trash::before {
    background-image: url('./assets/ic-trash.svg');
  }

  &.light::before {
    background-image: url('./assets/ic-light.svg');
  }

  &.entry-by-pass::before {
    background-image: url('./assets/ic-propusj.svg');
  }

  &.extinguishing_system::before {
    background-image: url('./assets/ic-fire.svg');
  }

  &.video-surveillance::before {
    background-image: url('./assets/ic-secure-camera.svg');
  }
}
