.call-button {
  padding: 0;
  vertical-align: bottom;
  border: none;
  outline: none;
  cursor: pointer;
}

.call-button__fullwidth {
  width: 100%;
}

.phone {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: var(--fontSize_18px);
  font-weight: bold;
}
