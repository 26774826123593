.container {
  position: relative;
  margin-right: 8px;
  display: inline-block;
  font-weight: normal;
  vertical-align: middle;

  &:hover .tooltip {
    display: block;
  }

  &:hover .icon svg {
    transform: rotate(180deg);
  }
}

.icon {
  display: flex;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: rgba(43, 135, 219, 0.1);
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.15s linear;
    transform-origin: center center;
  }
}

.tooltip {
  position: absolute;
  z-index: 20;
  top: calc(100% + 5px);
  left: 50%;
  margin-left: -4px;
  padding: 16px;
  display: none;
  background-color: white;
  border: 1px solid whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0);

  &::before,
  &::after {
    position: absolute;
    top: -4px;
    left: 50%;
    width: 8px;
    height: 8px;
    content: '';
    background-color: white;
    border-right: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    transform: rotate(225deg);
  }
}

.tooltip-value {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  color: #182930;
}

.price {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  color: #182930;

  &:first-of-type {
    margin-top: 0;
  }
}

.description {
  margin: 0;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;
}
