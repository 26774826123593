.container {
  position: absolute;
  z-index: 1;
  top: 70px;
  left: 0;
}

.wrapper {
  position: relative;
  margin: 0 auto;
  min-width: 704px;
  max-width: 1376px;
}

.tooltip {
  position: absolute;
  top: 16px;
  left: 22px;
  padding: 16px;
  display: flex;
  width: 287px;
  background-color: white;
  border: 1px solid var(--accent-main-primary);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.calendar {
  margin-top: 4px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/calendar.png');
  background-size: contain;
}
