.footer {
  margin-top: 64px;
  width: 100%;
  background-color: var(--gray6_100);
}

.container {
  margin: auto;
  padding: 64px 20px;
  width: 100%;
  min-width: 952px;
  max-width: 1376px;
}

@media print {
  .footer {
    display: none;
  }
}
