.agent-proofs-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.agent-proofs-title svg {
  margin-right: 4px;
}

.agent-reviews-title {
  margin-right: 16px;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
  color: var(--decorative-theme-dark);
}

.agent-reviews-title:hover {
  color: var(--accent-main-primary);
}

.agent-reviews-title svg {
  margin-right: 4px;
}
