.button {
  padding: 0;
  margin: 0 0 0 8px;
  display: flex;
  background: transparent;
  border: 0;
  color: var(--gray40_100);
  cursor: pointer;
}

.button:hover {
  color: var(--accent-main-primary);
}

.icon {
  margin-right: 8px;
  display: inline-block;
  width: 15px;
  height: 10px;
}
