.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 152px;
  background-color: var(--decorative-theme-white);
  text-align: center;
  font-weight: bold;
  color: var(--decorative-theme-dark);
}

.icon {
  margin-bottom: 6px;

  & > svg {
    width: 24px;
    height: 24px;
  }
}
