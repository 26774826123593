.status {
  padding-top: 16px;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: var(--gray60_100);
  align-items: center;
  gap: 5px;

  &::before {
    width: 6px;
    height: 6px;
    content: '';
    background-color: var(--accent-positive-primary);
    border-radius: 50%;
  }
}
