.container {
  background-color: white;
  position: relative;
}

.wrapper {
  margin-top: 20px;
  padding: 0 16px 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: #f1f2f4;
  border-radius: 4px;
  flex-wrap: wrap;
}

.title {
  padding-top: 10px;
  display: block;
  text-decoration: none;
  min-width: 30px;
}
