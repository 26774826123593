.root {
  margin: 0 auto;
  width: 100%;
  max-width: 632px;
  cursor: pointer;
  user-select: none;
}

.content {
  padding: 8px 12px;
  display: flex;
  width: 100%;
  background-color: white;
  border: 1px solid var(--stroke-border-default);
  border-radius: 4px;
  align-items: center;

  .disabled & {
    background: var(--gray6_100);
    border-color: var(--gray10_100);
    pointer-events: none;
    user-select: none;
  }
}

.icon-container {
  display: block;
  width: 16px;
  height: 16px;
}

.text-container {
  margin: 0 12px;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}

.text-container span {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);
  line-height: var(--unit_5);
}

.text-container_placeholder span {
  color: var(--stroke-control-hovered);
}
