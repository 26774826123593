.container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  overflow: hidden;
  flex: none;
  width: 40px;
  height: 40px;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--gray10_100);
}

.content {
  flex: auto;
  overflow: hidden;
}

.nowrap-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
