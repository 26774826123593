.anchor {
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-style: normal;
  cursor: pointer;
  color: var(--accent-main-primary);
  transition: color 0.15s;
  white-space: nowrap;
}

.anchor:hover {
  color: var(--control-main-primary-hovered);
}

@media print {
  .anchor {
    display: none;
  }
}
