.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.wrapper a {
  text-decoration: none;
}

.title {
  margin-left: 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.super-agent {
  margin-bottom: 8px;
}

.agent-name {
  margin-bottom: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: end;
  color: var(--decorative-theme-dark);
}

.proofs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
