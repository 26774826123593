.container {
  background-color: white;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;
  background-color: #e6f0ff;
}

.wrapper {
  padding: 24px 16px 0;
  margin: 0 24px 20px;
  background: #f1f2f4;
  border-radius: 4px;
}

.wrapper--compact {
  display: flex;
  justify-content: space-between;
}

.title {
  padding: 0 0 10px;
  display: inline-block;
  width: 50%;
  text-decoration: none;
}

.title--compact {
  width: auto;
  display: block;
}

.full-width {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container:not(.with--label):not(.with--compact) {
  .logo {
    margin-bottom: 16px;
  }
}

.container.with--compact {
  .wrapper {
    margin-bottom: 12px;
    padding-top: 8px;
  }
}

.label {
  margin: 8px auto 32px;
}
