.btn {
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
}

.btn-disabled {
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
}

.btn svg:hover {
  color: #d1152c;
}
