[aria-modal='true'][role='dialog'] {
  > div:first-child {
    border: 0 none;
    background: transparent;
  }

  > div:nth-child(2) {
    overflow: visible;
  }
}

.wrapper {
  padding: 0;
}

.text {
  margin-bottom: var(--unit_4);
}

.max span {
  color: var(--solid-gray200);
}

.input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  margin-right: 4px;
  width: 100%;
  font-size: 14px;
  color: var(--solid-gray900);
}
