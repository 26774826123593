.titles {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.bar {
  margin-top: 6px;
  background: linear-gradient(
    90deg,
    var(--gray6_100) 0,
    var(--gray6_100) 22%,
    transparent 0,
    transparent 78%,
    var(--gray6_100) 78%,
    var(--gray6_100) 100%
  );
  border-radius: 6px;
  height: 26px;
}

.fill {
  height: 26px;
  border-left: 1px solid var(--decorative-theme-dark);
  border-right: 1px solid var(--decorative-theme-dark);
  width: 56%;
  background-color: var(--surface-positive-default);
  margin: 0 auto;
  position: relative;
}

.label {
  display: inline-block;
  padding: 0 8px;
  text-align: center;
  color: var(--decorative-theme-white);
  background-color: var(--accent-positive-primary);
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.values {
  position: relative;
  margin-top: 4px;
  height: 20px;
  user-select: none;
}

.price_start,
.price_end {
  position: absolute;
  transform: translateX(-50%);
}

.price_start {
  left: 22%;
}

.price_end {
  left: 78%;
}
