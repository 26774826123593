.top {
  padding: 0 24px;
  width: 100%;
  min-width: 952px;
  max-width: 1360px;
}

@media print {
  .top {
    display: none;
  }
}
