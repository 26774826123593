.container {
  margin-top: 8px;

  & > * {
    margin-top: 8px;
  }

  @media print {
    display: none;
  }
}
