.container {
  max-width: 288px;
}

.range,
.link_wrapper {
  margin-top: 8px;
}

.description {
  margin-top: 4px;
}

.link {
  color: var(--accent-main-primary);
  text-decoration: none;
}

.faq_icon {
  cursor: pointer;
}
