@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';
@import '@cian/ui-kit/typography/variables.module.css';

.banner {
  text-decoration: none;
}

.logo {
  display: block;
  width: 192px;
  height: 32px;
  background: url('../../../packages/mortgage-assets/shared/img/cian-mortgage-logo.svg') no-repeat;
  margin-bottom: 12px;
}

.paragraph {
  color: var(--decorative-theme-dark);
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  margin-bottom: 4px;
}

.rate {
  font-weight: bold;
  color: var(--accent-main-primary);
  font-size: var(--fontSize_18px);
  line-height: var(--lineHeight_24px);
}
