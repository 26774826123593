@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.textarea {
  padding: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid;
  border-color: var(--gray20_100);
  border-radius: 4px;
  outline: none;
  transition: 0.15s all;
  resize: none;
}

.textarea:hover {
  border-color: var(--decorative-theme-dark);
}

.textarea:focus {
  border-color: var(--accent-main-primary);
  box-shadow: 0 0 0 1px var(--stroke-control-focused);
}
