.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10.6px;
  margin-top: auto;
  font-size: 16px;
  line-height: 22px;
  color: var(--decorative-theme-dark);
}

.name {
  display: flex;
  align-items: flex-start;
  margin-right: 9px;
  max-width: 57%;

  &.full_width {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }

  & svg {
    position: relative;
    margin-right: 6px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }
}

.transport {
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
}

.ellipsis {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
