.agent_info {
  margin: 12px 0 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row; /* stylelint-disable-line */
}

.agent_info .titles:not(:first-child) {
  margin: 0 0 0 8px;
}

.avatar {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: #e4e4e4;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}

.avatar-img {
  max-width: 100%;
  max-height: 100%;
}

.titles .title {
  margin: 4px 0 0;
  font-weight: bold;
}
