.address {
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-style: normal;
  cursor: pointer;
  color: var(--decorative-theme-dark);
  transition: color 0.15s;
}

.address:hover {
  color: var(--accent-main-primary);
}

@media print {
  .address {
    color: var(--decorative-theme-dark);
  }
}
