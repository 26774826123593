/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
.item {
  min-height: 286px;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
}

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.image_block {
  position: relative;
  width: 100%;
  height: 160px;
  min-height: 160px;
}

@media (max-width: 1023px) {
  .image_block {
    height: 120px;
    min-height: 120px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content_block {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 0;
  min-height: 149px;
}

.price {
  padding: 0 12.6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--decorative-theme-dark);
}

.title {
  margin-bottom: 4px;
  margin-top: 3px;
  padding: 0 12.6px;
  font-size: 16px;
  line-height: 22px;
  color: var(--decorative-theme-dark);
}

.address {
  margin-bottom: 4px;
  padding: 0 12.6px;
  font-size: 16px;
  line-height: 22px;
  color: var(--gray60_100);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-height: 66px;
  overflow: hidden;
}

.address_two_rows {
  -webkit-line-clamp: 2;
  min-height: 44px;
}

.address_one_row {
  -webkit-line-clamp: 1;
  min-height: 22px;
}

.ellipsis {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.photo_label {
  position: absolute;
  background: var(--decorative-theme-white);
  top: 12px;
  left: 12px;
  border-radius: 2px;
  padding: 0 8px 2px;
  color: var(--decorative-theme-dark);
  max-width: calc(100% - 24px);
  font-size: 12px;
  line-height: 18px;
}
