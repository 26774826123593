.container {
  display: flex;
  min-height: 28px;
}
.value {
  border: solid 1px #c9c9c9;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.action {
  flex: 0 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  svg {
    fill: #2b87db;
  }
}
.action-disabled {
  pointer-events: none;
  cursor: default;
  svg {
    fill: #b2cfee;
  }
}
.action-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: solid 1px #c9c9c9;
  border-right: none;
}
.action-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: solid 1px #c9c9c9;
  border-left: none;
}
