.breadcrumbs {
  margin: 16px 0;
}

.link {
  position: relative;
  font-family: Lato;
  font-size: 13px;
  line-height: 1.38;
  text-decoration: none;
  color: #7a7a7a;
  transition: color 0.15s;

  &::after {
    padding: 0 10px;
    display: inline-block;
    width: 5px;
    height: 8px;
    content: '';
    background: url('./icons/icon_arrow.svg') no-repeat center center;
  }

  &:last-child::after {
    display: none;
  }
}

a.link:hover {
  color: #121212;
}
