.container {
  border-radius: 4px;
  padding: var(--unit_3) var(--unit_4);
  background: var(--surface-neutral-default);
  display: flex;
  cursor: pointer;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--unit_3);
  height: 40px;
  width: 40px;
  flex-shrink: 0;
}

.arrow {
  margin-left: auto;
}
