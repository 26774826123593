.container {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
}

.updated {
  padding-right: 8px;
}

.in_hidden_base {
  position: relative;
  margin-left: 16px;
  padding-right: 8px;
  padding-left: 8px;
  display: inline-block;
  font-size: 9px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  background-color: var(--decorative-theme-dark);
  border-radius: 3px;
}
