.root {
  margin: 24px 0 32px;
  padding: 0 24px 24px;
  background-color: white;
  border: 1px solid #e8e9ec;
}

.cards {
  display: flex;
}

.card {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 280px;
  max-width: 316px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: 1250px) {
    /* на маленьких экранах показываем только 3 карточки */
    &:nth-child(4) {
      display: none;
    }
  }
}
