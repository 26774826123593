@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.frame {
  background: var(--gray6_100);
  max-width: 808px;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 20px 32px 28px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.frame.village {
  padding: 40px 30px 99px;
  background: url('./img/village-bg.svg');
  display: block;
}

.content {
  width: 744px;
}

.village .content {
  width: 50%;
  overflow: hidden;
  min-width: 382px;
}

.logo {
  width: 150px;
  height: 24px;
  background: url('./img/mortgage-logo.svg') no-repeat;
  background-size: contain;
  margin-bottom: 28px;
}

.village .logo {
  width: 258px;
  height: 40px;
  background: url('./img/mortgage-logo-white.svg') no-repeat;
  background-size: contain;
  margin-bottom: 28px;
}

.banks {
  width: 100%;
  margin-bottom: 8px;
}

.header-wrapper {
  margin-bottom: 28px;
}

.village .header-wrapper {
  margin-bottom: 16px;
}

.village .description-wrapper {
  margin-bottom: 40px;
}

.header-secondary-text {
  color: var(--gray60_100);
}
