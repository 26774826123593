.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.title {
  margin-left: 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.proofs {
  margin: 8px 0 0;
  padding: 0;
  list-style: none;
}

.proofs li {
  display: flex;
}

.proofs li + li {
  margin-top: 8px;
}

.proofs li svg {
  margin-right: 4px;
}

.badge {
  margin-top: var(--unit_1);
}
