.container {
  display: flex;
  align-items: center;
}

.number {
  font-weight: bold;
  font-size: 38px;
  margin-right: 8px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  width: 90px;
}
