.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.left {
  flex: 1;
  min-width: 0;
}

.container a {
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline;
}

.title {
  margin-bottom: 8px;
}

.agent-name {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
}

.container .agent-name:hover {
  text-decoration: none;
}

.agent-name span {
  margin-right: 8px;
}

.proofs {
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.proofs li {
  margin-right: 24px;
  display: inline;
  white-space: nowrap;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
}

.buttons .button + .button:not(:empty) {
  margin-left: var(--unit_5);
}

.avatar {
  margin-left: 16px;
}

.avatar span {
  width: 80px;
  height: 80px;
}

.phones-opened-info {
  margin-top: var(--unit_6);
}

.super-agent {
  margin-top: var(--unit_2);
}
