.hidden-block-segment {
  margin: 12px 0 8px;
  padding: 24px;
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid #e4e4e4;
  border-radius: 1px;
}

.hidden-block-segment > div {
  margin-bottom: 16px;
  text-align: center;
  color: #121212;
}

.hidden-block-segment > div:first-child {
  margin-bottom: 8px;
  font-weight: bold;
}

.hidden-block-segment > div:last-child {
  margin-bottom: 0;
}
