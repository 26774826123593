.logo {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
}

.logo a {
  text-decoration: none;
}

.img {
  width: 72px;
  height: 72px;
  border: 0.56px solid var(--gray10_100);
  border-radius: 4px;
  background: center no-repeat;
  background-size: contain;
}

.label {
  display: flex;
  margin-top: 8px;
  margin-right: auto;
}

.rating {
  display: flex;
  margin-right: auto;
}

.builder {
  all: unset;
  cursor: pointer;
}

.builder h3 {
  color: var(--accent-main-primary);
}
