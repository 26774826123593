.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  position: relative;
  margin-bottom: 16px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  color: black;
}

.close {
  position: absolute;
  top: -14px;
  right: -14px;
  padding: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.15s;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: #121212;
  }
}

.information {
  margin-bottom: 16px;

  div {
    font-size: 16px;
    line-height: 1.38;
    color: black;
    margin-bottom: var(--unit_1);
  }

  div:last-child {
    margin-bottom: 0;
  }
}

.error {
  padding: 12px 16px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
  background-color: rgba(255, 80, 80, 0.1);
}

.chart {
  margin-left: -12px;
  width: calc(100% + 24px);
}
