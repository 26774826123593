.button {
  height: 20px;
  width: 135px;
  display: flex;
  align-items: center;
  padding: 0 8px 2px;
  cursor: pointer;
  background-color: var(--accent-negative-primary);
  border: none;
  border-radius: 2px;
  outline: none;
  transition: 0.1s background-color ease;
}

.modern-button {
  height: 24px;
  padding: 2px 8px 3px;
  background: #ffe9eb;
  border-radius: 4px;
  border: none;
  outline: none;
  transition: 0.1s background-color ease;
}
