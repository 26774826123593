.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  width: fit-content;

  &--collapsed {
    .collapse-arrow {
      transform: rotate(180deg);
    }
  }
}

.collapse-text {
  margin-right: 4px;
}

.collapse-arrow {
  display: flex;
  transition: transform 150ms;
}
