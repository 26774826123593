.root {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--decorative-theme-dark);
}

.button:not(:last-child) {
  margin-right: 8px;
}
