.button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  border: 0;
  border-radius: 14px;
  background: rgba(4, 104, 255, 0.1);
  color: #2b87db;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: rgba(15, 72, 157, 0.2);
  color: #256ba5;
}

.text {
  margin-left: 6px;
  white-space: nowrap;
}

.disabled {
  background: #f1f2f4;
  color: #737a8e;
}
