.top-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: white;
  background-color: #121212;
  opacity: 0.9;
  animation: appear 0.2s linear;
}

.top-popup.error {
  background-color: #ff5050;
}

.close-outer {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transform: translate(0, -50%);

  svg {
    vertical-align: top;
    fill: rgba(255, 255, 255, 0.4);

    &:hover path {
      fill: white;
    }
  }
}

@keyframes appear {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}
