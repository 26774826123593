.container {
  margin-top: 40px;
  margin-bottom: 20px;
}

.wrapper {
  width: 100%;
  margin-top: 12px;
}

.wrapper div {
  width: 50%;
}

.wrapper div:nth-child(2n) {
  width: calc(50% - 20px);
  margin-left: 20px;
}

.collapse-button {
  margin-top: 24px;
}
