.container {
  display: flex;
  align-items: center;
}

.container h4 {
  color: var(--decorative-theme-dark);
}

.container:hover {
  * {
    color: var(--accent-main-primary);
  }
}

.link {
  text-decoration: none;
}

.text {
  margin-left: 8px;
  white-space: nowrap;
}

.text h5 {
  color: var(--decorative-theme-dark);
}

.arrow {
  margin-left: 8px;
  display: inline-flex;
  visibility: hidden;
  vertical-align: middle;
}

.link:hover .arrow {
  visibility: visible;
}
