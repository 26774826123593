.comment {
  position: relative;
  margin-bottom: 24px;
  display: block;
}

.text_block {
  border: 1px solid #e4e4e4;
  border-radius: 2px;

  textarea {
    padding: 16px 12px;
    display: block;
    width: calc(100% - 114px);
    height: 52px;
    font-size: 14px;
    line-height: 1.33;
    white-space: normal;
    word-wrap: break-word;
    resize: none;
    border: none;
    outline: none;

    &::placeholder {
      font-size: 14px;
      line-height: 1.43;
      color: var(--decorative-subway-msk-serpukhovskaya);
    }
  }

  textarea.noscroll {
    overflow: hidden;
  }
}

div.text_block--editing {
  border-color: #121212;
}

div.text_block--pointer:hover .cancel {
  display: block;
}

div.text_block--pointer {
  cursor: pointer;

  textarea {
    cursor: pointer;
  }
}

.hiddendiv {
  position: absolute;
  padding: 16px 126px 16px 12px;
  visibility: hidden;
  width: 100%;
  min-height: 52px;
  font-size: 14px;
  line-height: 1.33;
  white-space: pre-line;
  word-wrap: break-word;
  border: 1px solid;
  opacity: 0;
}

.button_submit {
  position: absolute;
  top: 12px;
  right: 12px;
}

.cancel {
  position: absolute;
  top: 18px;
  right: 12px;
  display: none;
  line-height: 0;
  cursor: pointer;
  color: rgba(255, 80, 80, 0.8);
  transition: color 0.15s;

  &:hover {
    color: #ff5050;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.preloader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 14px;
}

.error {
  padding: 0 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
  background-color: rgba(255, 80, 80, 0.1);
  transition: height 0.15s;

  &--open {
    margin-top: 12px;
    height: 42px;
  }
}

@media print {
  .text_block {
    display: none;

    &.print_mode {
      display: block;
      border-width: 0.53mm;
      border-color: var(--decorative-subway-msk-serpukhovskaya);
    }
  }
}
