.container {
  display: flex;
  gap: var(--unit_1);
  align-items: center;
}

.text {
  display: flex;
  gap: var(--unit_3);
  align-items: center;
}

.icon {
  background-image: url('./assets/icon.svg');
  width: 16px;
  height: 16px;
}

.secondary {
  color: var(--solid-gray200);
}
